import React from 'react';
import { Card } from 'react-bootstrap';
import Message from '../message/Message';
import update from 'immutability-helper';
import { IMessage, IListMessagesResponse, INewMessage, IMessageUpdated, IUser } from '../../Globals';
import './ChatFeed.css';

const LossLessJson = require('lossless-json');

type Props = {
    websocket: WebSocket
    threadHandle: string,
    user: IUser,
    voting: boolean
}

type State = {
    messages: Array<IMessage>
}

export default class ChatFeed extends React.Component<Props, State>{
    private onMessageListener: any;
    public state: State;

    public constructor(public props: Readonly<Props>) {
        super(props);
        let websocket = this.props.websocket;
        this.onMessageListener = (event: MessageEvent) => this.onSocketMessage(websocket, event);
        websocket.addEventListener('message', this.onMessageListener);
        this.state = { 
            messages: [],
        };
    }

    public componentWillUnmount(): void {
        this.props.websocket.removeEventListener('message', this.onMessageListener);
    } 

    public componentDidUpdate(prevProps: Props): void {
        if (!prevProps.voting && this.props.voting) {
            this.scrollBottom();
        }
    }

    // this is needed to re-render the component once the parent properties have changed.
    private onSocketMessage(_websocket: WebSocket, evt: MessageEvent): void {
        let obj = LossLessJson.parse(evt.data);
        switch(obj.tag) {
            case 'list-messages-response':
                var listMessagesResponse: IListMessagesResponse = obj;
                var messages = listMessagesResponse.data.messages.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);
                this.setState({ messages: [...messages] }, () => {this.scrollBottom();});
                break;
            case "new-message":
                let newMessage: INewMessage = obj;
                const newM = newMessage.data.message;
                if (newM.thread === this.props.threadHandle) {
                    this.setState({
                        messages: update(this.state.messages, {$push: [newM]})
                    }, () => {
                        // scroll to the bottom when a new message will be displayed
                        if (newM.sender.handle === this.props.user.handle || 
                            newM.meta.status === "accepted" ||
                            this.props.voting) {
                            this.scrollBottom();
                        }
                    });
                }
                break;
            case "message-updated":
                let updatedMessage: IMessageUpdated = obj;
                if (updatedMessage.data.message.thread === this.props.threadHandle) {
                    var idx = this.state.messages.findIndex((message) => {
                        return updatedMessage.data.message.id.toString() === message.id.toString();
                    });
                    this.setState(update(this.state, {messages: {[idx]: {$set: updatedMessage.data.message}}}));
                }
                break;
        }
    }

    private scrollBottom(): void {
        var objDiv = document.getElementById("imelation-feed")!!;
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    public render() {
        return (
            <Card className='imelation-feed' id='imelation-feed'>
                {
                    this.state.messages.map((message: IMessage) => {
                        return <Message 
                        message={message} 
                        user={this.props.user} 
                        websocket={this.props.websocket}
                        key={message.id.toString()}
                        voting={this.props.voting}/>
                    })
                }
            </Card>
        );
    }
}
