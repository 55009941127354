import React from 'react';
import { Card, Form } from 'react-bootstrap';
import './ChatInput.scss';
import { Globals, IMessage } from '../../Globals';
import VotingMessage from '../message/VotingMessage';
import { LosslessNumber } from 'lossless-json';

const LossLessJson = require('lossless-json');

type Props = {
    websocket: WebSocket,
    votingMessages: Array<IMessage>
};

type State = {
    votes: Array<number>
};

export default class ChatVoting extends React.PureComponent<Props, State> {

    public id: LosslessNumber;
    private onMessageListener: any;
    public state: State;

    constructor(public props: Readonly<Props>) {
        super(props);
        this.id = new LosslessNumber(0);
        this.onMessageListener = (event: MessageEvent) => this.onSocketMessage(this.props.websocket, event);
        this.props.websocket.addEventListener('message', this.onMessageListener);
        this.state = {
            votes: this.props.votingMessages.map(_ => 0)
        };
        this.castVote = this.castVote.bind(this);
    }

    private onSocketMessage(_websocket: WebSocket, evt: MessageEvent) {
        let obj = LossLessJson.parse(evt.data);
        this.id = obj.data.meta.id + 1;
    }

    public castVote(chosenIdx: number) {
        var newVotes = this.state.votes.slice();
        for (let i = 0; i < newVotes.length; i++) {
            newVotes[i] = i === chosenIdx ? 1 : -1;
        }
        this.setState({ votes: newVotes }, () => {
            Globals.voteMessage(
                this.props.websocket, 
                this.id, 
                this.props.votingMessages[chosenIdx].id, 
                'positive'
            );
        });
    }

    public sampleVotingMessages() {
        return this.props.votingMessages.map(
            (m, i) => (<VotingMessage
                message={m}
                voted={this.state.votes[i]}
                onVoteCast={this.castVote}
                messageKey={i}
                key={i} />)
        );
    }

    public render() {
        return (
            <Card>
                <Form className='mt-4 mb-2 ml-3 mr-3'>
                    <div>
                        <h4>Which response is the best?</h4>
                        {this.sampleVotingMessages()}
                    </div>
                </Form>
            </Card>
        );
    }
}
