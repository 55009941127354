import React from 'react';
import { IMessage, IUser, Globals } from '../../Globals';
import './Message.scss';
import { Button } from 'react-bootstrap';
import { LosslessNumber } from 'lossless-json';

type Props = {
    user: IUser,
    websocket: WebSocket,
    message: IMessage,
    voting: boolean,
}

type State = {
    click: boolean
}

export default class Message extends React.PureComponent<Props, State> {

    private id: LosslessNumber;
    public state: State;

    public constructor(public props: Readonly<Props>) {
        super(props);
        this.id = new LosslessNumber(0);

        this.state = {
            click: false
        };
    }

    private renderButtons() {
        var userRole = this.props.user.role;
        var senderRole = this.props.message.sender.role;

        if (userRole === 'patient' && senderRole === 'therapist')  {
            var feedbackHistory = this.props.message.meta.annotations['client-feedback'];
            var latestFeedback = 'none';
            if (feedbackHistory) {
                latestFeedback = feedbackHistory[feedbackHistory.length - 1].value;
            }
            return (
            <div>
                <Button 
                    variant={latestFeedback === 'helpful' ? 'success' : 'dark'} 
                    size='sm' 
                    className='py-0 mr-1' 
                    style={{fontSize: '0.6em'}} 
                    onClick={() => 
                        Globals.annotateMessage(
                            this.props.websocket, 
                            this.id, 
                            this.props.message.id, 
                            'client-feedback', 
                            latestFeedback === 'helpful'? 'none': 'helpful')
                    }> Helpful</Button>
                <Button 
                    variant={latestFeedback === 'not-helpful' ? 'danger' : 'dark'} 
                    size='sm' 
                    className='py-0 ml-1' 
                    style={{fontSize: '0.6em'}} 
                    onClick={() => 
                        Globals.annotateMessage(
                            this.props.websocket, 
                            this.id, 
                            this.props.message.id, 
                            'client-feedback', 
                            latestFeedback === 'not-helpful'? 'none': 'not-helpful')
                    }> Not Helpful</Button>
            </div>);
       } 
       else {
           return (<></>);
       }
    }

    public render() {
        var userRole = this.props.user.role;
        var senderRole = this.props.message.sender.role;
        var messageStatus = this.props.message.meta.status;
        var userHandle = this.props.user.handle;
        var senderHandle = this.props.message.sender.handle;

        // sender roles always sees their message, whether pending or not
        if (messageStatus === 'rejected') {
            return (<></>);
        }
        var messageCss = 'messages messages--';
        var timeCss = 'time--';
        var timeString = new Date(this.props.message.timestamp).toLocaleString() +  ' - ';

        if (userRole === senderRole) {
            if (messageStatus === 'pending') {
                timeString += userHandle === senderHandle ? this.props.message.sender.name : 'Anonymous Therapist';
                if (userHandle !== senderHandle && !this.props.voting) return (<></>);
                messageCss += 'pending';
            } else {
                timeString += this.props.message.sender.name;
                messageCss += 'sent';
            }
            timeCss += 'sent';
        } // receiver roles only see messages if they are accepted.
        else if(messageStatus === 'accepted')  {
            messageCss += 'received';
            timeCss += 'received';
            timeString += userRole === "patient" ? 'Therapist' : this.props.message.sender.name;
        } else {
            return (<></>);
        }

        return (
            <div>
                <div className={messageCss}
                        onClick={() => this.setState({click: !this.state.click})}>
                    <div 
                        className='message'>
                        {this.props.message.text}
                        {this.renderButtons()}
                    </div>
                </div>
                {this.state.click && (<div className={timeCss}>
                    {timeString}
                </div>)}
            </div>
            );
    }
}  
