import React from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import './Login.css';
import { Redirect, RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps<any>;

export default class Login extends React.PureComponent<Props> {
    // determined by react router
    registerUrl: '/nsf/register' | '/nsf/register-therapist';
    state: {
        type: 'login' | 'register',
        form : {
            username: string,
            password: string,
            displayName: string
        },
        loginSuccess: boolean, // redirect to home
        justRegistered: boolean, // show "successfully registered" dialog
        formErrors: {}, // show any backend errors
    };

    constructor(public props: Readonly<Props>) {
        super(props);
        this.registerUrl = this.props.match.params.type === 't' ? '/nsf/register-therapist' : '/nsf/register' ;
        this.state = {
            form : {
                username: '',
                password: '',
                displayName: ''
            },
            type: 'login', // show login or register
            loginSuccess: false, // redirect to home
            justRegistered: false, // show "successfully registered" dialog
            formErrors: {}, // show any backend errors
        };
        
        this.textEntered = this.textEntered.bind(this);
        this.submit = this.submit.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }

    public componentDidMount() {
        fetch('/nsf/home', {
	    mode: 'no-cors',
            method:'GET',
            credentials: 'include'
        }).then((response) => {
            this.setState({ loginSuccess: response.ok });
        });
    }

    public validateForm(): boolean {
        if (this.state.type === 'login') {
            return this.state.form.username.length > 0 &&
             this.state.form.password.length > 0;
        } else {
            return this.state.form.username.length > 0 &&
             this.state.form.password.length > 0 &&
             this.state.form.displayName.length > 0;
        }
    }


    public textEntered(event: any): void {
        this.setState({ 
            form: {
                ...this.state.form, 
                [event.target.id]: event.target.value
            }
        });
    }

    // toggles login and registration forms.
    public toggleForm(retainForm: boolean = false): void {
        this.setState({ 
            type : this.state.type === 'login' ? 'register' : 'login', 
            form : {
                username: retainForm ? this.state.form.username : '', 
                password: '', 
                displayName: '', 
            },
            formErrors: {}, 
        });
    }

    public submit(event: any): void {
        this.setState({ formErrors: {} });
        this.requestServer();
        event.preventDefault();
    }

    public requestServer(): void {
        let requestUrl = this.state.type === 'login' ? '/nsf/login' : this.registerUrl;
        fetch(requestUrl, {
            method: 'POST',
            body: JSON.stringify({ 
                Username: this.state.form.username, 
                Password: this.state.form.password, 
                'Display Name': this.state.form.displayName 
            }),
            credentials: 'include',
            headers: { 'Content-type': 'application/json' }
        })
            .then((response) => {
		return response.ok ? response : response.json();
	    })
        .then((response) => {
            if (!response.ok) {
	    console.log(response);
                for (var k in response) {
                    if (k !== "ok") {
                        this.setState({ formErrors: { ...this.state.formErrors, [k]: response[k] } });
                    }
                }
            }
            else if (this.state.type === 'register') {
                this.setState({ justRegistered: true }, () => this.toggleForm(true));
            } else {
                this.setState({ loginSuccess: true });
            }
        })
        .catch((error) => {
	console.error(this.state.formErrors);
          console.error('Error:', error);
        });
    }

    public render() {
        return (
            <div className='inner-container'>
                {this.state.loginSuccess 
                && (<Redirect to='/nsf/home'/>)}
                {!(this.props.match.params.type === 'c' || this.props.match.params.type === 't') && (<Redirect to="/"/>)}
                <Container className='box-title'>
                <div className='header'>Imelation {this.props.match.params.type === 't' ? '- Therapist login': ''}</div>
                </Container>

                <Container className='box-controller'>
                    <div 
                        className={'controller' + 
                            (this.state.type === 'login' ? ' selected-controller' : '')}
                        onClick={() => this.toggleForm(false)}>
                        Login
                    </div>
                    <div
                        className={'controller' + 
                            (this.state.type === 'register' ? ' selected-controller' : '')}
                        onClick={() => this.toggleForm(false)}>
                        Register
                    </div>
                </Container>

                <Container className='box'>
                    <Form onSubmit={this.submit}>
                        {/*  Username */}
                        <Form.Group controlId='username'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control 
                                type='username' 
                                placeholder='Enter username' 
                                value={this.state.form.username}
                                onChange={this.textEntered} 
                            />
                        </Form.Group>

                        {/*  Password */}
                        <Form.Group controlId='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type='password' 
                                placeholder='Password' 
                                value={this.state.form.password}
                                onChange={this.textEntered} />
                        </Form.Group>

                        {/*  Display Name */}
                        {this.state.type === 'register' 
                        && (
                                <Form.Group controlId='displayName'>
                                    <Form.Label>Display name</Form.Label>
                                    <Form.Control 
                                        type='display-name' 
                                        placeholder='Enter Name' 
                                        value={this.state.form.displayName}
                                        onChange={this.textEntered} />
                                </Form.Group>
                            )
                        }
                        
                        {/*  Error */}
                        {
                            Object.entries(this.state.formErrors).map(([k, v]) => {
                                return (
                                    <div 
                                        className='alert alert-danger' 
                                        key={k} role='alert'>
                                        {k}: {v}
                                    </div>
                                )
                            })
                        }
                        {/*  Success */}
                        {this.state.justRegistered 
                        && (
                            <div 
                                className='alert alert-success' 
                                key='just-registered' 
                                role='alert'>
                                Registration: Successful
                            </div>
                            )
                        }
                        {/*  Submit */}
                        <Button 
                            className='btn-block' 
                            variant='primary' 
                            type='submit' 
                            disabled={!this.validateForm()}>
                            Submit
                        </Button>
                    </Form>
                </Container>
            </div>
        );
    }
}
