
import React from 'react';
import { Navbar, DropdownButton, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Globals, IThread, IListThreadsResponse, IListMessagesResponse, IUser } from '../../Globals';
import { faUserCircle, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Redirect } from 'react-router-dom';
import './Header.scss';
import { LosslessNumber } from 'lossless-json';

const LossLessJson = require('lossless-json');

type Props = {
    websocket: WebSocket,
    user: IUser
}


export default class Header extends React.PureComponent<Props> {
    private id: LosslessNumber;
    private onMessageListener: any;
    private onCloseListener: any;

    public state: {
        loggedIn: true,
        threads: Array<IThread>
    };

    public constructor(public props: Readonly<Props>) {
        super(props);
        this.id = new LosslessNumber(0);

        let websocket = this.props.websocket;
        this.onMessageListener = (event: MessageEvent) => this.onSocketMessage(websocket, event);
        this.onCloseListener = (event: CloseEvent) => this.onSocketClose(websocket, event);
        websocket.addEventListener('message', this.onMessageListener);
        websocket.addEventListener('close',this.onCloseListener);

        this.state = {
            threads: [],
            loggedIn: true
        };
    }

    public componentWillUnmount() {
        this.props.websocket.removeEventListener('message',this.onMessageListener); 
        this.props.websocket.removeEventListener('close', this.onCloseListener);
    }

    private onSocketMessage(websocket: WebSocket, evt: MessageEvent) {
        let obj = LossLessJson.parse(evt.data);
        this.id = obj.data.meta.id + 1;
        
        switch(obj.tag) {
            case 'list-threads-response':
                let threadResponse: IListThreadsResponse = obj;
                var threads: Array<IThread> = threadResponse.data.threads;
                var otherThreads = null;
                // first time called
                if (this.state.threads.length === 0) {
                    var defaultThread = threads.find((thread: IThread) => {
                        return thread.handle === this.props.user.defaultThread;
                    });
                    otherThreads = threads.filter((thread: IThread) => {
                        return thread.handle !== this.props.user.defaultThread;
                    });

                    this.setState({threads: [defaultThread, ...otherThreads]}, 
                        () => { 
                            Globals.requestMessages(this.props.websocket, this.id, defaultThread!!.handle)
                        }
                    );
                }
                // updating our existing list, our first thread stays unchanged.
                else {
                    otherThreads = threads.filter((thread: IThread) => {
                        return thread.handle !== this.state.threads[0].handle;
                    });
                    this.setState({threads: [this.state.threads[0], ...otherThreads]});
                }
                break;
            case 'list-messages-response':
                let listMessagesResponse: IListMessagesResponse = obj;
                if (listMessagesResponse.data.thread === this.state.threads[0].handle) {
                    break;
                } else {
                    Globals.setVoterRegistrations(websocket, this.id, this.state.threads[0].handle, listMessagesResponse.data.thread);
                    var selectedThread = this.state.threads.filter((thread: IThread) => {
                        return thread.handle === listMessagesResponse.data.thread;
                    })[0];
                    otherThreads = this.state.threads.filter((thread: IThread) => {
                        return thread.handle !== listMessagesResponse.data.thread;
                    });
                    this.setState({threads: [selectedThread, ...otherThreads]});
                }
            break;
        }
    } 

    private onSocketClose(_websocket: WebSocket, _evt: CloseEvent) {
        // we will unregister ourselves from this thread handle.
        if (this.state.threads.length !== 0) {
            Globals.setVoterRegistrations(this.props.websocket, this.id, this.state.threads[0].handle);
        }
    } 

    private logout() {
        this.props.websocket.close();
        fetch('/nsf/logout', {
                method: 'GET',
                credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                this.setState({ loggedIn: false });
            } else {
                console.log('There is some error with logging out!');
            }
        });
    }

    
    private renderThreadName(): JSX.Element {
        // Threads have not been retrieved yet
        if (this.state.threads.length === 0) {
            return (<></>);
        }
        // Client view of the threads
        else if (this.state.threads.length === 1) {
            return (<Navbar.Brand>{this.state.threads[0].handle}</Navbar.Brand>);
        } else {
        // Therapist view of the threads
            return (
                <DropdownButton id='dropdown' title={this.state.threads[0].handle}>
                    {
                        this.state.threads.slice(1).map((thread: IThread) => {
                            return (<DropdownItem
                                    onSelect={() => {
                                        Globals.requestMessages(
                                            this.props.websocket, 
                                            this.id,
                                            thread.handle)
                                    }}
                                    key={thread.handle}>
                                        {thread.handle}
                                    </DropdownItem>);
                        })
                    }
                </DropdownButton>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <>
            <Navbar bg='light'>
                {this.renderThreadName()}
                <Navbar.Collapse className='navbar'>
                    <h4 id='username' className='navbar-username'>
                        {this.props.user.name}
                    </h4>
                    <FontAwesomeIcon icon={faUserCircle} size='2x' />
                    <Button 
                        id='clickLogout' 
                        className='ml-3 pl-2 pr-2 pt-1 pb-1' 
                        onClick={() => this.logout()}>
                        <FontAwesomeIcon icon={faSignInAlt} size='2x' />
                    </Button>
                </Navbar.Collapse>
            </Navbar>
            {(!this.state.loggedIn) && (
                <Redirect to='/login/c' push={true}/>
            )}
            </>
        )
    }
}