import React from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import './ChatInput.scss';
import { Globals} from '../../Globals';
import { LosslessNumber } from 'lossless-json';

const LossLessJson = require('lossless-json');

type Props = {
    websocket: WebSocket,
    threadHandle: string,
    disabled: boolean,
};

type State = {
    message: string
};

export default class ChatInput extends React.PureComponent<Props, State> {

    public id: LosslessNumber;
    private onMessageListener: any;
    public state: State;

    constructor(public props: Readonly<Props>) {
        super(props);
        this.id = new LosslessNumber(0);
        this.onMessageListener = (event: MessageEvent) => this.onSocketMessage(this.props.websocket, event);
        this.props.websocket.addEventListener('message', this.onMessageListener);

        this.state = {
            message: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    private onSocketMessage(_websocket: WebSocket, evt: MessageEvent) {
        let obj = LossLessJson.parse(evt.data);
        this.id = obj.data.meta.id + 1;
        switch (obj.tag) {
            case 'list-messages-response':
                // Chat has switched to a different thread
                this.setState({ message: '' });
                return;
        }
    }

    public handleChange(event: any) {
        this.setState({ message: event.target.value });
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        Globals.sendMessage(this.props.websocket, this.id, this.state.message, this.props.threadHandle);
        this.setState({ message: '' });
    }


    public render() {
        return (
            <Card>
                <Form className='mt-4 mb-2 ml-3 mr-3' onSubmit={this.handleSubmit}>
                    <div>
                        <Form.Group controlId='messageBox'>
                            <Form.Control as='textarea'
                                disabled={this.props.disabled}
                                placeholder={this.props.disabled ? 'awaiting therapist responses' : 'What would you like to say?'} rows={3}
                                value={this.state.message}
                                onChange={this.handleChange} />
                        </Form.Group>
                        <Container>
                            <Button variant='primary' type='submit' className='float-right'>
                                Talk
                            </Button>
                        </Container>
                    </div>
                </Form>
            </Card>
        );
    }
}
