import React from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import Login from './components/login/Login';
import Home from './components/home/Home';
import './App.css';
export default class App extends React.Component {
    render() {
        return (
            <Router>
                <div className='root-container'>
                    <Route path='/login/:type' component={Login}/>
                    <Route path='/nsf/home'>
                        <Home/>
                    </Route>
                    <Route exact path='/' component={Login}>
                        <Redirect to='/login/c'/>
                    </Route>
                </div>
            </Router>
        );
    }
}
