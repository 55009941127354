import React from 'react';
import { IMessage } from '../../Globals';
import './Message.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVoteYea } from '@fortawesome/free-solid-svg-icons';

type Props = {
    message: IMessage,
    voted: number,
    onVoteCast: (index: number) => void,
    messageKey: number,
}

type State = {
};

export default class VotingMessage extends React.PureComponent<Props, State> {

    public constructor(public props: Readonly<Props>) {
        super(props);
    }

    public render() {
        return (
            <div className='messages messages--voting'>
                <div className='message'>
                    {this.props.message.text.length < 50 ? 
                    this.props.message.text : this.props.message.text.slice(0, 50) + '...'}
                </div>
                {this.props.voted === 0 && 
                    <FontAwesomeIcon 
                        className= 'mt-2 pull-right' 
                        icon={faVoteYea} 
                        size='1x'
                        onClick={() => this.props.onVoteCast(this.props.messageKey)}/>
                }
                {this.props.voted === 1 && 
                    <FontAwesomeIcon 
                        className= 'mt-2 pull-right' 
                        icon={faVoteYea} 
                        size='1x'/>
                }
            </div>
        );
    }
}  
