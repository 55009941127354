import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ChatFeed from './feed/ChatFeed';
import Header from './header/Header';
import './Home.scss';
import {
    Globals, ISelfResponse,
    IUser,
    IListMessagesResponse,
    INewMessage,
    IMessageUpdated,
    IListUsersResponse,
    IMessage,
    IVoterRegistrations,
    IUserUpdate,
    IUserDeleted,
    INewUser
} from '../Globals';

import ChatInput from './input/ChatInput';
import { Redirect } from 'react-router';
import ChatVoting from './input/ChatVoting';
import { LosslessNumber } from 'lossless-json';
const LosslessJSON = require('lossless-json');

type Props = {
}

type State = {
    loggedIn: boolean,
    threadHandle: string,
    user: IUser,
    voting: boolean,
    chatDisabled: boolean
}

class IPendingMessages {
    messages: Array<IMessage>
    authors: Set<string> // author handles

    public constructor() {
        this.messages = new Array<IMessage>();
        this.authors = new Set<string>();
    }

    public push(m: IMessage) {
        this.messages.push(m)
        this.authors.add(m.sender.handle)
    }

    public pop(m: IMessage): boolean {
        var removeIdx = this.messages.findIndex(message => message.id.toString() === m.id.toString());
        if (removeIdx > -1) {
            this.messages.splice(removeIdx, 1);
            this.authors.delete(m.sender.handle);
            return true;
        }
        return false;
    }

    // we need all active therapists to have pending messages, 
    // not all pending messages to have active therapists.
    public readyForVote(activeTherapists: Set<string>): boolean {
        if (this.authors.size === 0) return false;
        for (let t of activeTherapists) {
            if (!this.authors.has(t)) return false
        }
        return true
    }

}

export default class Home extends React.Component<Props, State> {

    public id: LosslessNumber;
    private pendingMessages: IPendingMessages;
    private activeTherapists: Set<string>;
    
    public websocket: WebSocket;
    private onOpenListener: any;
    private onMessageListener: any;
    private onErrorListener: any;

    public state: State;
    public constructor(props: Readonly<Props>) {
        super(props);
        this.id = new LosslessNumber(0);
        this.pendingMessages = new IPendingMessages();
        this.activeTherapists = new Set<string>();
        this.state = {
            loggedIn: true,
            threadHandle: '',
            user: {name: ''} as IUser,
            voting: false,
            chatDisabled: false
        };


        const websocket = this.websocket = new WebSocket(Globals.WEBSOCKET_URI + '/nsf/home-socket');
        // the scope gets weird in here since we are adding 'function' callbacks.
        this.onOpenListener = (event: MessageEvent) => this.onSocketOpen(websocket, event);
        this.onMessageListener = (event: MessageEvent) => this.onSocketMessage(websocket, event);
        this.onErrorListener = (event: MessageEvent) => this.onSocketError(websocket, event);
        websocket.addEventListener('open',this.onOpenListener);
        websocket.addEventListener('message', this.onMessageListener);
        websocket.addEventListener('error', this.onErrorListener);
    }

    public componentWillUnmount() {
        this.websocket.removeEventListener('open',this.onOpenListener); 
        this.websocket.removeEventListener('message', this.onMessageListener);
        this.websocket.removeEventListener('error', this.onErrorListener);
    }

    public onSocketOpen(websocket: WebSocket, _evt: Event) {
        Globals.selfRequest(websocket, this.id);
    }

    public onSocketError(_websocket: WebSocket, evt: Event) {
        console.log('Error with connecting to websocket: ', evt);
        fetch('/nsf/logout', {
            method: 'GET',
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                this.setState({ loggedIn: false });
            } else {
                console.log('There is some error with logging out!');
            }
        });
        this.setState({loggedIn: false});
    }

    public onSocketMessage(websocket: WebSocket, evt: MessageEvent): void {
        const obj = LosslessJSON.parse(evt.data);
        this.id = obj.data.meta.id + 1;
        if (obj.tag !== 'heartbeat-request') {
            console.log(obj);
        }
        switch (obj.tag) {
            case 'heartbeat-request':
                Globals.heartbeatResponse(websocket, this.id);
                break;
            case 'self-response':
                const selfResponse: ISelfResponse = obj;
                this.setState({ 
                    user: {
                        ...selfResponse.data.self.user, 
                        defaultThread: selfResponse.data.defaultThread || 'therapists'
                    }
                }, () => Globals.requestThreads(websocket, this.id));
                break;
            case 'list-messages-response':
                const listMessagesResponse: IListMessagesResponse = obj;
                if (this.state.user.role === 'therapist') {
                    this.pendingMessages = new IPendingMessages();
                    this.activeTherapists = new Set<string>();
                    this.setState({voting: false, chatDisabled: false});
                    listMessagesResponse.data.messages
                    .filter(m => m.meta.status === 'pending')
                    .forEach(this.pendingMessages.push);
                }
                this.setState({ threadHandle: listMessagesResponse.data.thread }, 
                    () => Globals.listUsersOnThread(websocket, this.id, this.state.threadHandle)
                );
                break;
            case "list-users-response":
                const listUsersResponse: IListUsersResponse = obj;
                if (this.state.user.role === 'therapist') {
                    this.activeTherapists.add(this.state.user.handle);
                    listUsersResponse.data.users
                    .filter(u => u.status === "online" && u.user.role === "therapist")
                    .map(u => u.user).forEach(u => {
                        this.activeTherapists.add(u.handle);
                    });
                }
                break;
            case "new-message":
                const newMessage: INewMessage = obj;
                if (this.state.user.role === 'therapist') {
                    const newM: IMessage = newMessage.data.message;
                    if (newM.thread === this.state.threadHandle && newM.meta.status === 'pending') {
                        this.pendingMessages.push(newM);
                        // we just submitted this message, disable the chat feed.
                        if (newM.sender.handle === this.state.user.handle) {
                            this.setState({ chatDisabled: true });
                        }
                        // Check if we are ready to vote now.
                        if (this.pendingMessages.readyForVote(this.activeTherapists)) {
                            this.setState({ voting: true });
                        }
                    }
                }
                break;
            case "message-updated":
                const updatedMessage: IMessageUpdated = obj;
                if (this.state.user.role === 'therapist') {
                    const updatedM: IMessage = updatedMessage.data.message;
                    if (updatedM.thread === this.state.threadHandle) {
                        if (updatedM.meta.status !== 'pending') {
                            if (this.pendingMessages.pop(updatedM) &&
                                updatedM.sender.handle === this.state.user.handle) {
                                this.setState({chatDisabled: false, voting: false});
                            }
                        }
                    }
                }
                break;
            case "new-user":
                const newUser: INewUser = obj;
                // new therapists do not create threads
                if (this.state.user.role === 'therapist') {
                    if (newUser.data.userStatus.user.role === 'patient') {
                        Globals.requestThreads(websocket, this.id);
                    }
                }
                break;
            case "user-deleted":
                const userDeleted: IUserDeleted = obj;
                // new therapists do not create threads
                if (this.state.user.role === 'therapist') {
                    if (userDeleted.data.userStatus.user.role === 'patient') {
                        Globals.requestThreads(websocket, this.id);
                    }
                }
                break;
            case "user-update":
                const userUpdate: IUserUpdate = obj;
                if (this.state.user.role === 'therapist') {
                    if (userUpdate.data.userStatus.status === 'offline' &&
                        this.activeTherapists.has(userUpdate.data.userStatus.user.handle)) {
                            this.activeTherapists.delete(userUpdate.data.userStatus.user.handle)
                    }
                }
                break;
            case 'voter-registrations':
                const voterRegistrations: IVoterRegistrations = obj;
                if (this.state.user.role === 'therapist') {
                    if (voterRegistrations.data.threads.includes(this.state.threadHandle)) {
                        this.activeTherapists.add(voterRegistrations.data.user.handle)
                    }
                    else if (this.activeTherapists.has(voterRegistrations.data.user.handle)) {
                        if (!voterRegistrations.data.threads.includes(this.state.threadHandle)) {
                            this.activeTherapists.delete(voterRegistrations.data.user.handle)
                        }
                    }
                }
                break;
        }
    }

    // we append the handle to shuffle the orderings of the data (non identical voting options across clients)
    public samplePendingMessages(): Array<IMessage> {
        return this.pendingMessages.messages
        .filter(m => m.sender.handle !== this.state.user.handle)
        .sort((a, b) => Globals.hashCode(this.state.user.handle + a.text) - Globals.hashCode(this.state.user.handle + b.text))
        .slice(0, Math.min(this.activeTherapists.size, Globals.VOTES_PER_THERAPIST));
    }

    public render(): JSX.Element {
        return (
            <>
                {/* TODO: this doesn't work properly */}
                {!this.state.loggedIn && (<Redirect to='/' push={true}/>)}
                {/* Header: takes care of thread selection and list message requests */}
                <Header websocket={this.websocket} user={this.state.user}/>
                <Container>
                    {/* Just displays the current thread name. This is retrieved from list message responses. */}
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card>
                                <Card.Body className='text-center h4'>
                                    {this.state.threadHandle}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            {/* Displays messages and handles all the message related details */}
                            <ChatFeed 
                                websocket={this.websocket}
                                threadHandle={this.state.threadHandle}
                                user={this.state.user}
                                voting={this.state.voting}
                            />
                            {/* Chat input related details */}
                            {this.state.voting ? 
                                <ChatVoting 
                                    websocket={this.websocket}
                                    votingMessages={this.samplePendingMessages()}
                                />
                            :
                                <ChatInput
                                    websocket={this.websocket} 
                                    threadHandle={this.state.threadHandle}
                                    disabled={this.state.user.role === 'therapist' && this.state.chatDisabled}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </>

        );
    }
}
