import { LosslessNumber } from 'lossless-json';

import configData from "../config.json"

const LossLessJson = require('lossless-json');
export class Globals {

    public static BACKEND = configData.backend;
    public static VOTES_PER_THERAPIST = configData.num_votes_per_therapist;
    public static WEBSOCKET_URI = configData.websocket;

    public static socketSend(socket: WebSocket, message: any) {
        socket.send(LossLessJson.stringify(message));
    }

    public static hashCode(s: string): number {
        return s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
    }

    public static listUsersOnThread(websocket: WebSocket, id: LosslessNumber, threadHandle: string) {
        var listUsersRequest: IListUsersRequest = {
            tag: 'list-users-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                thread: threadHandle
            }
        }
        Globals.socketSend(websocket, listUsersRequest);
    }

    public static heartbeatResponse(websocket: WebSocket, id: LosslessNumber) {
        var hbResponse: IHeartBeatResponse = {
            tag: 'heartbeat-response',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                }
            }
        };
        Globals.socketSend(websocket, hbResponse);
    }

    public static requestThreads(websocket: WebSocket, id: LosslessNumber) {
        var threadList: IListThreadsRequest = {
            tag: 'list-threads-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                limit: 100,
                after: null
            }
        };
        Globals.socketSend(websocket, threadList);
    }

    public static voteMessage(socket: WebSocket, id: LosslessNumber, messageId: LosslessNumber, value: string) {
        var setVoteRequest: IAnnotationRequest = {
            tag: 'new-annotation-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                id: messageId,
                kind: 'vote',
                value
            }
        };
        Globals.socketSend(socket, setVoteRequest);
    }

    public static sendMessage(socket: WebSocket, id: LosslessNumber, message: string, threadHandle: string) {
        var postMessage: IPostMessageRequest = {
            tag: 'post-message-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                thread: threadHandle,
                text: message
            }
        };
        Globals.socketSend(socket, postMessage);
    }

    public static selfRequest(socket: WebSocket, id: LosslessNumber) {
        var selfRequest: ISelfRequest = {
            tag: 'self-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                }
            }
        };
        Globals.socketSend(socket, selfRequest);
    }

    public static requestMessages(websocket: WebSocket, id: LosslessNumber, threadHandle: string) {
        var messagesRequest: IListMessagesRequest = {
            tag: 'list-messages-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                thread: threadHandle
            }
        };
        Globals.socketSend(websocket, messagesRequest);
    }

    public static setVoterRegistrations(websocket: WebSocket, id: LosslessNumber, oldThread: string, newThread?: string) {
        var setVoterRegistrations: ISetVoterRegistrations = {
            tag: 'set-voter-registrations',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                changes: {
                    [oldThread]: false
                }
            }
        };
        if (newThread) {
            setVoterRegistrations.data.changes[newThread] = true;
        }
        Globals.socketSend(websocket, setVoterRegistrations);
    }

    public static annotateMessage(socket: WebSocket, id: LosslessNumber, messageId: LosslessNumber, kind: string, value: string) {
        var setMessageTagRequest: IAnnotationRequest = {
            tag: 'new-annotation-request',
            data: {
                meta: {
                    id,
                    timestamp: Date.now()
                },
                id: messageId,
                kind: kind,
                value: value
            }
        };

        Globals.socketSend(socket, setMessageTagRequest);
    }
}

export type IUser = {
    handle: string,
    name: string,
    role: 'patient' | 'therapist',
    defaultThread: string
}

export type IHeartBeatRequest = {
    tag: 'heartbeat-request',
    data: {
        meta: IMeta
    }
}

export type IHeartBeatResponse = {
    tag: 'heartbeat-response',
    data: {
        meta: IMeta
    }
}

export type IMeta = {
    id: LosslessNumber,
    timestamp: number,
    respondingTo?: number
}

export type IAnnotations = {
    [key: string]: Array<{
        id: LosslessNumber,
        user: IUser,
        value: string
    }>
}

export type IMessage = {
    id: LosslessNumber,
    thread: string
    sender: IUser,
    timestamp: number,
    text: string
    meta: {
        status: 'offline' | 'online' | 'pending' | 'accepted' | 'rejected',
        annotations: IAnnotations
    }
}

export type IThread = {
    handle: string,
    description: string,
    permissions: string
}


export type ISelfRequest = {
    tag: 'self-request',
    data: {
        meta: IMeta
    }
}

export type ISelfResponse = {
    tag: 'self-response',
    data: {
        meta: IMeta,
        self: {
            user: IUser,
            status: 'offline' | 'online' | 'pending' | 'accepted'
        },
        defaultThread: string
    }
}

export type IPostMessageRequest = {
    tag: 'post-message-request',
    data: {
        meta: IMeta,
        thread: string,
        text: string
    }
}

export type INewMessage = {
    tag: 'new-message',
    data: {
        meta: IMeta,
        message: IMessage,
        text: string
    }
}

export type IListThreadsRequest = {
    tag: 'list-threads-request',
    data: {
        meta: IMeta,
        limit: number,
        after: null
    }
}

export type IListThreadsResponse = {
    tag: 'list-threads-response',
    data: {
        meta: IMeta,
        threads: Array<IThread>
    }
}

export type IListUsersRequest = {
    tag: 'list-users-request',
    data:
    {
        meta: IMeta,
        limit: number,
        after: string
    } | {
        meta: IMeta,
        thread: string
    }
}

export type IListUsersResponse = {
    tag: 'list-users-response',
    data:
    {
        meta: IMeta,
        users: Array<{
            user: IUser,
            status: "online" | "offline"
        }>
    }
}

export type IUserUpdate = {
    tag: "user-update",
    data:
    {
        meta: IMeta,
        userStatus:
        {
            user: IUser,
            status: "online" | "offline"
        }
    }
}

export type IUserDeleted = {
    tag: "user-deleted",
    data:
    {
        meta: IMeta,
        userStatus:
        {
            user: IUser,
            status: "online" | "offline"
        }
    }
}

export type INewUser = {
    tag: 'new-user',
    data:
    {
        meta: IMeta,
        userStatus:
        {
            user: IUser,
            status: "offline"
        }
    }
}

export type IListMessagesRequest = {
    tag: 'list-messages-request',
    data: {
        meta: IMeta,
        thread: string
    }
}

export type IAnnotationRequest = {
    tag: 'new-annotation-request',
    data: {
        meta: IMeta,
        id: LosslessNumber,
        kind: string,
        value: string
    }
}

export type IEditAnnotationRequest = {
    tag: 'edit-annotation-request',
    data: {
        meta: IMeta,
        id: LosslessNumber,
        value: string
    }
}

export type ITagRequest = {
    tag: 'set-user-tag-request' | 'set-thread-tag-request' | 'set-message-tag-request' | 'set-annotation-tag-request',
    data: {
        meta: IMeta,
        id: LosslessNumber,
        key: string,
        value: string
    }
}

export type IMessageUpdated = {
    tag: 'message-updated',
    data: {
        meta: IMeta,
        message: IMessage
    }
}

export type IListMessagesResponse = {
    tag: 'list-messages-response',
    data: {
        meta: IMeta,
        thread: string,
        messages: Array<IMessage>
    }
}

export type ISetVoterRegistrations = {
    tag: 'set-voter-registrations',
    data: {
        meta: IMeta,
        changes: {
            [key: string]: boolean
        }
    }
}

export type IVoterRegistrations = {
    tag: 'voter-registrations',
    data: {
        meta: IMeta,
        user: IUser,
        threads: Array<string>
    }
}
